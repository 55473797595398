<template>
    <div id="EsportsMember_legendary">
        <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="name" label="等级名称" align="center"></el-table-column>
            <el-table-column prop="level" label="会员级别" align="center"> </el-table-column>
            <!-- <el-table-column prop="equity_id" label="房费折扣" align="center"></el-table-column> -->
            <el-table-column prop="integral_rate" label="积分比例" align="center"></el-table-column>
            <!-- <el-table-column prop="start_date" label="充值升级要求" align="center"></el-table-column> -->
            <el-table-column prop="end_date" label="是否固定等级" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.is_fixed == 0 ? '不固定' : '固定' }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="equity_num" label="会员权益" align="center">
                <template slot-scope="scope">
                    <div style="color: #0486FE;">
                        {{ scope.row.equity_num }}种
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column label="升降级策略" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="handleLook(scope.row)">查看</el-button>
                </template>
            </el-table-column> -->
            <el-table-column prop="expire_date" label="有效期" align="center"></el-table-column>
            <el-table-column prop="name" label="操作" width="120">
                <template slot-scope="scope">
                    <el-button type="text" @click="handleConfig(scope.row)">等级配置</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>

export default {
    data() {
        return {
            tableData: [{}]
        }
    },
    created() {
        this.getList()
    },
    mounted() { },
    computed: {},
    methods: {
        // 查看
        handleLook() { },
        // 配置等级
        handleConfig(row) {
            console.log('row', row)
            let id = row.id
            this.$router.push({ path: `/EsportsMember/ConfigLevel?id=${id}` })
        },
        getList() {
            let data = {
                type_id: 2,//会员类型 1电商 2酒店 3旅游
            }
            console.log('获取电竞会员等级列表data', data);
            this.my_request('admin/member/grade', data, 'GET').then(res => {
                console.log('获取电竞会员等级列表结果', res);
                let { code, data } = res.data
                if (200 == code) {
                    this.tableData = data.list.filter(item => {
                        return item.level === "特级";
                    });
                }
            })
        },

    }
}

</script>

<style lang="scss" scoped></style>